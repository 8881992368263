// @flow
import { Grid, GridItem, H, Query, SectionLink, } from '@haaretz/htz-components';
import { parseComponentProp, parseStyleProps, } from '@haaretz/htz-css-tools';
import React from 'react';
import { useFela, } from 'react-fela';

import type { Asset, AssetType, } from '@haaretz/htz-components';
import type { Node, } from 'react';

import MarketSummaryQuery from './marketSummary.graphql';

type AssetData = {
  name: string,
  value: number,
  changePercentage: number,
  id: string,
  type: AssetType,
};

type Props = {
  asset: AssetData,
  miscStyles: ?Object,
  customLink: Object,
};

const numToString: number => string = num => num.toLocaleString('he', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

type ItemProps = {
  children: (className: string) => Node,
  title: ?string,
  miscStyles: ?Object,
};

const Item = ({ children, title, miscStyles, }: ItemProps): Node => {
  const { css, } = useFela();
  const childrenClassName = css(({ theme, }) => ({
    flexGrow: '1',
    extend: [
      theme.type(0, { untilBp: 'l', }),
      theme.type(2, { fromBp: 'l', }),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  }));
  return (
    <div
      className={css(({ theme, }) => ({
        display: 'flex',
        flexBasis: '100%',
        flexDirection: 'column',
        flexGrow: '1',
        flexShrink: '1',
        position: 'relative',
        extend: [
          theme.mq(
            { from: 'l', },
            {
              alignItems: 'center',
              justifyContent: 'center',
              ':not(:last-child)': {
                ':after': {
                  backgroundColor: theme.color('neutral', '-4'),
                  content: '""',
                  end: '0',
                  height: '80%',
                  position: 'absolute',
                  top: '50%',
                  width: '2px',
                  transform: 'translateY(-50%)',
                },
              },
            }
          ),
        ],
      }))}
    >
      <span
        className={css(({ theme, }) => ({
          fontWeight: '700',
          color: theme.color('neutral', '-3'),
          ...theme.type(-2),
          extend: [
            theme.mq({ until: 'l', }, { display: 'none', }),
          ],
        }))}
      >
        {title}
      </span>
      {children(childrenClassName)}
    </div>
  );
};

Item.defaultProps = {
  title: null,
  miscStyles: null,
};

const MarketSummary = ({ asset, miscStyles, customLink, }: Props): Node => {
  const { name, value, changePercentage, id, type, } = asset;
  const { css, theme, } = useFela();
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
      })}
    >
      <div
        className={css({
          backgroundColor: theme.color('neutral', '-10'),
          marginBottom: '2px',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          extend: [
            theme.mq({ until: 's', }, { padding: '1rem', }),
            theme.mq({ from: 's', }, { paddingTop: '1rem', }),
            parseComponentProp(
              'textAlign',
              [ { until: 's', value: 'start', }, { from: 's', value: 'center', }, ],
              theme.mq,
              (prop, value) => ({ [prop]: value, })
            ),
          ],
        })}
      >
        <H
          className={css({
            extend: [
              theme.type(0, { untilBp: 'l', }),
              theme.type(1, { fromBp: 'l', }),
              theme.mq({ from: 's', }, { marginBottom: '1rem', }),
              theme.mq({ until: 's', }, theme.type(-1)),
            ],
          })}
        >
          {name}
        </H>
        <div
          className={css({
            display: 'flex',
            extend: [
              parseComponentProp(
                'flexDirection',
                [ { until: 'l', value: 'column', }, { from: 'l', value: 'row', }, ],
                theme.mq,
                (prop, value) => ({ [prop]: value, })
              ),
              theme.mq(
                { from: 's', },
                { paddingBottom: '5px', }
              ),
            ],
          })}
        >
          <Item title="שער">
            {className => <span className={className}>{value ? numToString(value) : '-'}</span>}
          </Item>
          <Item
            title="% שינוי"
            miscStyles={{
              ...(
                changePercentage
                  ? {
                    color:
                      Number(changePercentage) < 0
                        ? theme.color('negative')
                        : theme.color('positive'),
                  }
                  : {}
              ),
              direction: 'ltr',
              ':before': {
                content:
                // Number(changePercentage) > 0
                //   ? '"+"'
                    // :
                    Number(changePercentage) < 0
                      ? '"-"'
                      : '""',
              },
              ':after': {
                content: changePercentage ? '"%"' : '',
              },
            }}
          >
            {className => (
              <span className={className}>
                {changePercentage ? numToString(Math.abs(Number(changePercentage))) : '-'}
              </span>
            )}
          </Item>
        </div>
      </div>
      <SectionLink
        href={customLink.path || {
          pathname: type ? `/asset/${type}` : '/',
          query: {
            assetId: id,
            section: type,
          },
        }}
        as={customLink.path || `/${type || ''}/${id || ''}`}
        miscStyles={{
          backgroundColor: theme.color('neutral', '-10'),
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          display: [ { until: 's', value: 'none', }, ],
        }}
      >
        <span>{customLink.display || `לבורסת ${name}`}</span>
      </SectionLink>
    </div>
  );
};

export default ({ miscStyles, assetsId, customLinks, }: {
    miscStyles: ?Object,
    assetsId: string[],
    customLinks: ?Object,
  }
): Node => (
  <Query query={MarketSummaryQuery} variables={{ assetsId, }}>
    {({ loading, error, data, }) => {
      if (error) return null;
      if (loading) return null;
      const { assets = [], } = data || {};
      return (
        <Grid
          gutter={{
            onServerRender: 1,
            queries: [ { until: 'l', value: 1, }, { from: 'l', value: 4, }, ],
          }}
        >
          {assets.map((asset: Asset, index: number) => (
            <GridItem key={asset.id} width={1 / 3} miscStyles={{ display: 'flex', }}>
              <MarketSummary
                key={asset.id}
                miscStyles={miscStyles}
                asset={asset}
                customLink={customLinks ? customLinks[index] || {} : {}}
              />
            </GridItem>
          ))}
        </Grid>
      );
    }}
  </Query>
);
