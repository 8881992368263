// @flow
import {
  AdSlot,
  Grid,
  GridItem,
  Query,
  // SectionLink,
  SeoData,
  SortableTable,
  StaticTable,
  // TabbedGraph,
  TableGraphConnector,
  type Period,
} from '@haaretz/htz-components';
// import { parseComponentProp, } from '@haaretz/htz-css-tools';
// import Link from 'next/link';
import React, { Fragment, } from 'react';
import { FelaComponent, useFela, } from 'react-fela';

import type { StyleProps, } from '@haaretz/htz-css-tools';
import type { Node, } from 'react';

import MarketSummary from '../components/MarketSummary/MarketSummary';
import PageRow from '../components/PageRow/PageRow';
import RowItem from '../components/RowItem/RowItem';
import MainLayout from '../layouts/MainLayout';

import exchangeQuery from './queries/exchangeQuery.graphql';

// import makamQuery from './queries/makamQuery.graphql';
// import marketsHot from './queries/marketsHot.graphql';
import useAllAdUnits from '../components/AdManager/AdUnitsProvider';
import useAdUnitPathTemplate, { createAdSlotProps, } from '../components/AdManager/useAdUnitPathTemplate';

type Props = {
  url: {
    pathname: string,
    query: {
      section: string,
    },
    asPath: string,
  },
};

const numToString: (number | string) => string = num => (typeof num === 'number'
  ? num.toLocaleString('he', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  : num);

// function Makam(): Node {
//   return (
//     <FelaComponent
//       style={({ theme, }) => ({
//         display: 'flex',
//         extend: [
//           parseComponentProp(
//             'justifyContent',
//             [ { until: 's', value: 'space-between', }, { from: 's', value: 'center', }, ],
//             theme.mq,
//             (prop, value) => ({ [prop]: value, })
//           ),
//         ],
//       })}
//     >
//       <FelaComponent
//         style={({ theme, }) => ({
//           extend: [ theme.mq({ from: 's', }, { order: '1', }), ],
//         })}
//         as="span"
//       >
//         הנצפות ברשת
//       </FelaComponent>
//       <Link href={{ pathname: 'https://w.makam.co.il/', }}>
//         <a tabIndex="-1">
//           <img src="/static/images/logo_makam.png" alt="makamLogo" width="18px" height="18px" />
//         </a>
//       </Link>
//     </FelaComponent>
//   );
// }

// type MakamLinkProps = {
//   miscStyles: ?StyleProps,
// };

// function MakamLink({ miscStyles, }: MakamLinkProps): Node {
//   return (
//     <SectionLink href="https://w.makam.co.il/" icon={false} miscStyles={miscStyles} target="_blank">
//       <img src="/static/images/makam_realtime.png" alt="makamRealtime" height="18px" />
//     </SectionLink>
//   );
// }

function index({
  url: {
    query: { section, },
    asPath,
  },
}: Props): Node {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { theme, } = useFela();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const adUnits = useAllAdUnits();
const adUnitPathTemplate = useAdUnitPathTemplate();

  const periods: Array<{ display: string, value: Period, }> = [
    { value: 'day', display: 'יומי', },
    { value: 'week', display: 'שבועי', },
    { value: 'month', display: 'חודשי', },
    { value: 'year1', display: 'שנתי', },
    { value: 'year3', display: '3 שנים', },
    { value: 'year5', display: 'מקסימום', },
  ];

  return (
    <MainLayout section={section}>
      <SeoData
        title="אתר שוק ההון, מניות ובורסה המוביל בישראל - TheMarker Finance"
        description="אתר פיננס דה מרקר מספק מידע מורחב ועדכני משוקי ההון בישראל, וול סטריט, אירופה ואסיה אודות מניות, אגרות חוב, קרנות נאמנות, קרנות סל, נגזרים, דולר, שוק המטבעות ובעלי עניין"
        path={asPath}
      />

      <Fragment>
        <PageRow
          miscStyles={{
            marginInlineStart: [ { until: 's', value: '-1rem', }, ],
            marginInlineEnd: [ { until: 's', value: '-1rem', }, ],
          }}
        >
          <MarketSummary
            miscStyles={{ flexGrow: '1', }}
            assetsId={[ '142', '90012', '30.10.!DJI', ]}
            customLinks={{
              /* eslint-disable no-useless-computed-key */
              [0]: { display: 'עוד מדדים', path: '/indices', },
              [1]: { display: 'שערי מט"ח נוספים', path: '/exchange', },
              [2]: { display: 'עוד מדדים', path: '/indices', },
              /* eslint-enable no-useless-computed-key */
            }}
          />
        </PageRow>
        <PageRow miscStyles={{ marginBottom: [ { from: 'l', value: '0', }, ], }}>
          <RowItem title="מבט לשווקים">
            <FelaComponent
              style={() => ({
                extend: [ theme.mq({ from: 'l', }, { display: 'none', }), ],
              })}
            >
              <Query
                query={exchangeQuery}
                variables={{
                  ids: [
                    '142',
                    '137',
                    '164',
                    '145',
                    '9001',
                    '9004',
                    '29.10.@CCO',
                    '30.10.!DJI',
                    '33.10.!SPX',
                  ],
                }}
              >
                {({ loading, error, data, }) => {
                  if (loading || error) return null;
                  const { assets = [], } = data || {};
                  return (
                    <StaticTable
                      data={assets}
                      linkContent
                      columns={[
                        {
                          title: 'שם נייר',
                          name: 'name',
                          styles: {
                            fontWeight: '700',
                            maxWidth: '17rem',
                            paddingStart: '2rem',
                          },
                          headerStyle: {
                            width: [ { until: 'l', value: '45%', }, ],
                          },
                          render: value => value,
                        },
                        {
                          title: 'שער אחרון',
                          name: 'value',
                          styles: {
                            paddingStart: '2rem',
                          },
                          render: value => numToString(value),
                        },
                        {
                          title: '% שינוי',
                          percentage: true,
                          name: 'changePercentage',
                          styles: function styles(value: number): StyleProps {
                            return {
                              ...(value
                                ? {
                                  color:
                                      value < 0 ? theme.color('negative') : theme.color('positive'),
                                }
                                : {}),
                              direction: 'ltr',
                              fontWeight: '700',
                              paddingStart: '2rem',
                              position: 'relative',
                              textAlign: 'start',
                            };
                          },
                          render: value => `
                                ${value > 0 ? '' : value < 0 ? '-' : ''}
                                ${value ? numToString(Math.abs(value)) : value}%
                              `,
                        },
                      ]}
                    />
                  );
                }}
              </Query>
            </FelaComponent>
            <TableGraphConnector
              miscStyles={{ display: [ { until: 'l', value: 'none', }, ], }}
              assetsId={[
                '142',
                '137',
                '164',
                '145',
                '9001',
                '9004',
                '29.10.@CCO',
                '30.10.!DJI',
                '33.10.!SPX',
              ]}
              periods={periods}
            />
          </RowItem>
        </PageRow>
        <PageRow
          miscStyles={{
            paddingTop: '3rem',
            paddingBottom: '4rem',
            backgroundColor: [ { from: 'l', value: theme.color('white'), }, ],
          }}
        >
          <RowItem>
            <AdSlot {...createAdSlotProps(adUnits['finance.themarker.com.banner1'], adUnitPathTemplate)} />
          </RowItem>
        </PageRow>
        <PageRow>
          <Grid>
            <GridItem width={[ { until: 'l', value: 1, }, { from: 'l', value: 1 / 2, }, ]}>
              <RowItem title="מניות תל אביב">
                <SortableTable
                  queryPrefix="TA"
                  filters={[ { section: 'index', subSection: '2', }, ]}
                  type="stock"
                  fragment="
                        name
                        value
                        changePercentage
                      "
                  link={{
                    href: '/stock',
                    as: '/stock',
                    text: 'לבורסת תל אביב',
                  }}
                  fields={[
                    {
                      name: 'name',
                      display: 'שם נייר',
                      sortingOrder: 'asc',
                      style: () => ({
                        fontWeight: '700',
                        paddingStart: '2rem',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }),
                      headerStyle: { width: '45%', },
                      value: ({ name, }) => name,
                    },
                    {
                      name: 'value',
                      display: 'שער אחרון',
                      sortingOrder: 'desc',
                      value: ({ value, }) => numToString(value),
                    },
                    {
                      name: 'changePercentage',
                      display: '% שינוי',
                      sortingOrder: 'desc',
                      style: ({ changePercentage, }) => ({
                        ...(changePercentage
                          ? {
                            color:
                                changePercentage < 0
                                  ? theme.color('negative')
                                  : theme.color('positive'),
                          }
                          : {}),
                        direction: 'ltr',
                        fontWeight: '700',
                        paddingEnd: '2rem',
                        position: 'relative',
                        textAlign: 'start',
                      }),
                      value: ({ changePercentage, }) => `
                            ${changePercentage > 0 ? '' : changePercentage < 0 ? '-' : ''}
                            ${
                              changePercentage
                                ? numToString(Math.abs(changePercentage))
                                : changePercentage
                            }%
                          `,
                    },
                  ]}
                  initialSort="changePercentage"
                  count={5}
                />
              </RowItem>
            </GridItem>
            <GridItem
              width={1 / 2}
              miscStyles={{
                display: [ { until: 'l', value: 'none', }, { from: 'l', value: 'flex', }, ],
                flexDirection: 'column',
              }}
            >
              <RowItem
                title="מדדים של קסם"
                miscStyles={{
                  marginBottom: '2rem',
                  fontFamily: theme.fontStacks.commercial,
                  extend: [ theme.type(0), ],
                }}
                isCommercial
              >
                <Grid gutter={2} miscStyles={{ flexGrow: '1', }}>
                  <GridItem width={1 / 2}>
                    <AdSlot
                      {...createAdSlotProps(adUnits['finance.themarker.com.fujiright'], adUnitPathTemplate)}
                      wrapperMiscStyles={{ height: '100%', }}
                    />
                  </GridItem>
                  <GridItem width={1 / 2}>
                    <AdSlot
                      {...createAdSlotProps(adUnits['finance.themarker.com.fujileft'], adUnitPathTemplate)}
                      wrapperMiscStyles={{ height: '100%', }}
                    />
                  </GridItem>
                </Grid>
              </RowItem>
            </GridItem>
          </Grid>
        </PageRow>
        <PageRow>
          <Grid>
            <GridItem
              width={[ { until: 'l', value: 1, }, { from: 'l', value: 1 / 2, }, ]}
              miscStyles={{ marginBottom: [ { until: 'l', value: '4rem', }, ], }}
            >
              <RowItem title="מניות בנאסד״ק">
                <SortableTable
                  queryPrefix="Nasdaq"
                  filters={[ { section: 'index', subSection: '29.10.@CCO', }, ]}
                  type="stock"
                  fragment="
                        name
                        value
                        changePercentage
                      "
                  link={{
                    href: '/stock',
                    as: '/stock',
                    text: 'לבורסת וול סטריט',
                  }}
                  fields={[
                    {
                      name: 'name',
                      display: 'שם נייר',
                      sortingOrder: 'asc',
                      style: () => ({
                        fontWeight: '700',
                        maxWidth: '17rem',
                        paddingStart: '2rem',
                      }),
                      headerStyle: { width: '45%', },
                      value: ({ name, }) => name,
                    },
                    {
                      name: 'value',
                      display: 'שער אחרון',
                      sortingOrder: 'desc',
                      value: ({ value, }) => numToString(value),
                    },
                    {
                      name: 'changePercentage',
                      display: '% שינוי',
                      sortingOrder: 'desc',
                      style: ({ changePercentage, }) => ({
                        ...(changePercentage
                          ? {
                            color:
                                changePercentage < 0
                                  ? theme.color('negative')
                                  : theme.color('positive'),
                          }
                          : {}),
                        direction: 'ltr',
                        fontWeight: '700',
                        paddingEnd: '2rem',
                        position: 'relative',
                        textAlign: 'start',
                      }),
                      value: ({ changePercentage, }) => `
                            ${changePercentage > 0 ? '' : changePercentage < 0 ? '-' : ''}
                            ${
                              changePercentage
                                ? numToString(Math.abs(changePercentage))
                                : changePercentage
                            }%
                          `,
                    },
                  ]}
                  initialSort="changePercentage"
                  count={5}
                />
              </RowItem>
            </GridItem>
            <GridItem width={[ { until: 'l', value: 1, }, { from: 'l', value: 1 / 2, }, ]}>
              <RowItem title="מניות ארביטראז׳">
                <SortableTable
                  queryPrefix="Arbitrage"
                  filters={[ { section: 'index', subSection: '-2000', }, ]}
                  type="stock"
                  fragment="
                        dualName
                        symbol
                        arbGap
                        dualID
                      "
                  link={{
                    href: '/arbitrage',
                    as: '/arbitrage',
                    text: 'לרשימה המלאה',
                  }}
                  fields={[
                    {
                      name: 'dualName',
                      display: 'שם נייר',
                      sortingOrder: 'asc',
                      style: () => ({
                        fontWeight: '700',
                        maxWidth: '17rem',
                        paddingStart: '2rem',
                      }),
                      headerStyle: {
                        width: [ { until: 'l', value: '45%', }, { from: 'l', value: '35%', }, ],
                      },
                      value: ({ dualName, }) => dualName,
                      path: { type: 'type', id: 'dualID', },
                    },
                    {
                      name: 'symbol',
                      display: 'סימול בוול סטריט',
                      sortingOrder: 'asc',
                      value: ({ symbol, }) => symbol,
                      path: { type: 'type', id: 'id', },
                    },
                    {
                      name: 'arbGap',
                      display: "פער ארביטראז'",
                      sortingOrder: 'desc',
                      style: ({ arbGap, }) => ({
                        ...(arbGap
                          ? {
                            color: arbGap < 0 ? theme.color('negative') : theme.color('positive'),
                          }
                          : {}),
                        direction: 'ltr',
                        fontWeight: '700',
                        paddingEnd: '2rem',
                        position: 'relative',
                        textAlign: 'start',
                      }),
                      value: ({ arbGap, }) => `
                            ${arbGap > 0 ? '' : arbGap < 0 ? '-' : ''}
                            ${arbGap ? `${numToString(Math.abs(arbGap))}%` : '-'}
                          `,
                    },
                  ]}
                  initialSort="arbGap"
                  count={5}
                />
              </RowItem>
            </GridItem>
          </Grid>
        </PageRow>
        <PageRow>
          <Grid>
            <GridItem
              width={[ { until: 'l', value: 1, }, { from: 'l', value: 1 / 2, }, ]}
              miscStyles={{ marginBottom: [ { until: 'l', value: '4rem', }, ], }}
            >
              <Query
                query={exchangeQuery}
                variables={{
                  ids: [ '90012', '9001', '90041', '9004', '90011', ],
                }}
              >
                {({ loading, error, data, }) => {
                  if (loading || error) return null;
                  const { assets = [], } = data || {};
                  return (
                    <RowItem title="מט״ח">
                      <StaticTable
                        type="currency"
                        link={{
                          href: '/exchange',
                          as: '/exchange',
                          text: 'למדור מט״ח',
                        }}
                        linkContent
                        data={assets}
                        columns={[
                          {
                            title: 'שם מטבע',
                            name: 'name',
                            styles: {
                              fontWeight: '700',
                              paddingStart: '2rem',
                            },
                            headerStyle: { width: '45%', },
                            render: value => value || '-',
                          },
                          {
                            title: 'שער אחרון',
                            name: 'value',
                            styles: {
                              paddingStart: '2rem',
                            },
                            render: value => value || '-',
                          },
                          {
                            title: '% שינוי',
                            percentage: true,
                            name: 'changePercentage',
                            styles: function styles(value: number): StyleProps {
                              return {
                                ...(value
                                  ? {
                                    color:
                                        value < 0
                                          ? theme.color('negative')
                                          : theme.color('positive'),
                                  }
                                  : {}),
                                direction: 'ltr',
                                fontWeight: '700',
                                paddingStart: '2rem',
                                position: 'relative',
                                textAlign: 'start',
                              };
                            },
                            render: value => `
                                  ${value > 0 ? '' : value < 0 ? '-' : ''}
                                  ${value ? `${numToString(Math.abs(value))}%` : '-'}
                                `,
                          },
                        ]}
                      />
                    </RowItem>
                  );
                }}
              </Query>
            </GridItem>
            <GridItem
              width={1}
              miscStyles={{
                order: [ { from: 'l', value: '3', }, ],
                marginTop: [ { from: 'l', value: '4rem', }, ],
                marginBottom: [ { until: 'l', value: '4rem', }, ],
              }}
            >
              <AdSlot {...createAdSlotProps(adUnits['finance.themarker.com.banner2'], adUnitPathTemplate)} />
            </GridItem>
            <GridItem
              width={[ { until: 'l', value: 1, }, { from: 'l', value: 1 / 2, }, ]}
              miscStyles={{ marginBottom: [ { until: 'l', value: '4rem', }, ], }}
            >
              <Query
                query={exchangeQuery}
                variables={{
                  ids: [ 'BTC', 'LTC', 'ETH', 'ZEC', 'XRP', ],
                }}
              >
                {({ loading, error, data, }) => {
                  if (loading || error) return null;
                  const { assets = [], } = data || {};
                  return (
                    <RowItem title="מטבעות דיגיטליים">
                      <StaticTable
                        type="currency"
                        link={{
                          href: '/crypto',
                          as: '/crypto',
                          text: 'למדור מטבעות דיגיטליים',
                        }}
                        linkContent
                        data={assets}
                        columns={[
                          {
                            title: 'שם מטבע',
                            name: 'name',
                            styles: {
                              fontWeight: '700',
                              maxWidth: '17rem',
                              paddingStart: '2rem',
                            },
                            headerStyle: {
                              width: [ { until: 'l', value: '45%', }, { from: 'l', value: '35%', }, ],
                            },
                            render: value => value,
                          },
                          {
                            title: 'שער אחרון',
                            name: 'value',
                            styles: {
                              paddingStart: '2rem',
                            },
                            render: value => numToString(value),
                          },
                          {
                            title: '% שינוי',
                            percentage: true,
                            name: 'changePercentage',
                            styles: function styles(value: number): StyleProps {
                              return {
                                ...(value
                                  ? {
                                    color:
                                        value < 0
                                          ? theme.color('negative')
                                          : theme.color('positive'),
                                  }
                                  : {}),
                                direction: 'ltr',
                                fontWeight: '700',
                                paddingStart: '2rem',
                                position: 'relative',
                                textAlign: 'start',
                              };
                            },
                            render: value => `
                                  ${value > 0 ? '' : value < 0 ? '-' : ''}
                                  ${value ? numToString(Math.abs(value)) : value}%
                                `,
                          },
                        ]}
                      />
                    </RowItem>
                  );
                }}
              </Query>
            </GridItem>
          </Grid>
        </PageRow>
        {/* <Query query={makamQuery} variables={{ input: { numOfResults: 9, }, }}>
          {({ loading, error, data, }) => {
            if (loading || error) return null;
            const { viewedStocks, } = data;
            const ids = viewedStocks.reduce((res, { agentMetadata: { agentMeta, }, }) => {
              agentMeta && res.push(agentMeta);
              return res;
            }, []);
            return (
              <Fragment>
                <PageRow id="marketHot" miscStyles={{ display: [ { until: 'l', value: 'none', }, ], }}>
                  <RowItem title="הכי חם בשוק">
                    <TabbedGraph
                      filters={[ { section: 'index', subSection: '1', }, ]}
                      tabs={[
                        {
                          display: 'עולות',
                          control: 'graph-up',
                          sortBy: 'changePercentage',
                          sortOrder: 'desc',
                          tabFilters: [ { section: 'index', subSection: '2', }, ],
                          periods,
                        },
                        {
                          display: 'יורדות',
                          control: 'graph-down',
                          sortBy: 'changePercentage',
                          sortOrder: 'asc',
                          tabFilters: [ { section: 'index', subSection: '2', }, ],
                          periods,
                        },
                        {
                          display: 'פעילות',
                          control: 'graph-active',
                          sortBy: 'volume',
                          sortOrder: 'desc',
                          headers: [
                            {
                              display: 'שם אפיק',
                              value: 'name',
                              style: {
                                paddingStart: '1rem',
                                paddingTop: '0.75rem',
                                paddingBottom: '0.75rem',
                                paddingEnd: '2rem',
                                marginBottom: '1rem',
                                backgroundColor: theme.color('neutral', '-6'),
                              },
                            },
                            {
                              display: 'מחזור',
                              value: 'volume',
                              style: {
                                paddingEnd: '2rem',
                                paddingTop: '0.75rem',
                                paddingBottom: '0.75rem',
                                marginBottom: '1rem',
                                backgroundColor: theme.color('neutral', '-6'),
                              },
                            },
                            {
                              display: '% שינוי',
                              percentage: true,
                              value: 'changePercentage',
                              style: {
                                paddingEnd: '2rem',
                                paddingTop: '0.75rem',
                                paddingBottom: '0.75rem',
                                marginBottom: '1rem',
                                backgroundColor: theme.color('neutral', '-6'),
                              },
                            },
                          ],
                          periods,
                        },
                        {
                          display: <Makam />,
                          control: 'graph-mostViewed',
                          sortBy: 'name',
                          sortOrder: 'asc',
                          assetsId: ids,
                          link: <MakamLink miscStyles={{ marginTop: '1rem', }} />,
                          periods,
                        },
                        {
                          display: 'עולות שנתי',
                          control: 'graph-upYearly',
                          sortBy: 'yearlyYield',
                          sortOrder: 'desc',
                          headers: [
                            {
                              display: 'שם אפיק',
                              value: 'name',
                              style: {
                                paddingStart: '1rem',
                                paddingTop: '0.75rem',
                                paddingBottom: '0.75rem',
                                paddingEnd: '2rem',
                                marginBottom: '1rem',
                                backgroundColor: theme.color('neutral', '-6'),
                              },
                            },
                            {
                              display: 'שער אחרון',
                              value: 'value',
                              style: {
                                paddingEnd: '2rem',
                                paddingTop: '0.75rem',
                                paddingBottom: '0.75rem',
                                marginBottom: '1rem',
                                backgroundColor: theme.color('neutral', '-6'),
                              },
                            },
                            {
                              display: '% שינוי',
                              percentage: true,
                              value: 'yearlyYield',
                              style: {
                                paddingEnd: '2rem',
                                paddingTop: '0.75rem',
                                paddingBottom: '0.75rem',
                                marginBottom: '1rem',
                                backgroundColor: theme.color('neutral', '-6'),
                              },
                            },
                          ],
                          periods,
                        },
                        {
                          display: 'יורדות שנתי',
                          control: 'graph-downYearly',
                          sortBy: 'yearlyYield',
                          sortOrder: 'asc',
                          headers: [
                            {
                              display: 'שם אפיק',
                              value: 'name',
                              style: {
                                paddingStart: '1rem',
                                paddingTop: '0.75rem',
                                paddingBottom: '0.75rem',
                                paddingEnd: '2rem',
                                marginBottom: '1rem',
                                backgroundColor: theme.color('neutral', '-6'),
                              },
                            },
                            {
                              display: 'שער אחרון',
                              value: 'value',
                              style: {
                                paddingEnd: '2rem',
                                paddingTop: '0.75rem',
                                paddingBottom: '0.75rem',
                                marginBottom: '1rem',
                                backgroundColor: theme.color('neutral', '-6'),
                              },
                            },
                            {
                              display: '% שינוי',
                              percentage: true,
                              value: 'yearlyYield',
                              style: {
                                paddingEnd: '2rem',
                                paddingTop: '0.75rem',
                                paddingBottom: '0.75rem',
                                marginBottom: '1rem',
                                backgroundColor: theme.color('neutral', '-6'),
                              },
                            },
                          ],
                          periods,
                        },
                      ]}
                    />
                  </RowItem>
                </PageRow>
                <PageRow miscStyles={{ display: [ { from: 'l', value: 'none', }, ], }}>
                  <RowItem title="הכי חם בשוק" miscStyles={{ marginBottom: '4rem', }}>
                    <Query
                      query={marketsHot}
                      variables={{
                        filters: [ { section: 'index', subSection: '1', }, ],
                        upDownFilters: [ { section: 'index', subSection: '2', }, ],
                        count: 6,
                        offset: 0,
                        sortByUp: 'changePercentage',
                        sortOrderUp: 'desc',
                        sortByDown: 'changePercentage',
                        sortOrderDown: 'asc',
                        sortByActive: 'volume',
                        sortOrderActive: 'desc',
                        watchedIds: ids,
                        sortByUpYearly: 'yearlyYield',
                        sortOrderUpYearly: 'desc',
                        sortByDownYearly: 'yearlyYield',
                        sortOrderDownYearly: 'asc',
                      }}
                    >
                      {({ error, loading, data, }) => {
                        if (error || loading) {
                          return null;
                        }
                        const { up, down, active, watched, upYearly, downYearly, } = data;
                        return (
                          <Fragment>
                            <StaticTable
                              title="עולות יומי"
                              data={up.assets}
                              linkContent
                              columns={[
                                {
                                  title: 'שם נייר',
                                  name: 'name',
                                  styles: {
                                    fontWeight: '700',
                                    maxWidth: '17rem',
                                    paddingStart: '2rem',
                                  },
                                  render: value => value,
                                },
                                {
                                  title: '% שינוי',
                                  percentage: true,
                                  name: 'changePercentage',
                                  styles: function styles(value: number): StyleProps {
                                    return {
                                      ...(value
                                        ? {
                                          color:
                                              value < 0
                                                ? theme.color('negative')
                                                : theme.color('positive'),
                                        }
                                        : {}),
                                      direction: 'ltr',
                                      fontWeight: '700',
                                      paddingStart: '2rem',
                                      position: 'relative',
                                      textAlign: 'start',
                                    };
                                  },
                                  render: value => `
                                              ${value > 0 ? '' : value < 0 ? '-' : ''}
                                              ${value ? numToString(Math.abs(value)) : value}%
                                            `,
                                },
                              ]}
                              miscStyles={{ marginBottom: '4rem', }}
                              trMiscStyles={{ marginBottom: '4rem', }}
                            />
                            <StaticTable
                              title="יורדות יומי"
                              data={down.assets}
                              linkContent
                              columns={[
                                {
                                  title: 'שם נייר',
                                  name: 'name',
                                  styles: {
                                    fontWeight: '700',
                                    maxWidth: '17rem',
                                    paddingStart: '2rem',
                                  },
                                  render: value => value,
                                },
                                {
                                  title: '% שינוי',
                                  percentage: true,
                                  name: 'changePercentage',
                                  styles: function styles(value: number): StyleProps {
                                    return {
                                      ...(value
                                        ? {
                                          color:
                                              value < 0
                                                ? theme.color('negative')
                                                : theme.color('positive'),
                                        }
                                        : {}),
                                      direction: 'ltr',
                                      fontWeight: '700',
                                      paddingStart: '2rem',
                                      position: 'relative',
                                      textAlign: 'start',
                                    };
                                  },
                                  render: value => `
                                              ${value > 0 ? '' : value < 0 ? '-' : ''}
                                              ${value ? numToString(Math.abs(value)) : value}%
                                            `,
                                },
                              ]}
                              miscStyles={{ marginBottom: '4rem', }}
                              trMiscStyles={{ marginBottom: '4rem', }}
                            />
                            <StaticTable
                              title="פעילות"
                              data={active.assets}
                              linkContent
                              columns={[
                                {
                                  title: 'שם נייר',
                                  name: 'name',
                                  styles: {
                                    fontWeight: '700',
                                    maxWidth: '17rem',
                                    paddingStart: '2rem',
                                  },
                                  render: value => value,
                                },
                                {
                                  title: 'שער אחרון',
                                  name: 'value',
                                  styles: {
                                    paddingStart: '2rem',
                                  },
                                  render: value => numToString(value),
                                },
                              ]}
                              miscStyles={{ marginBottom: '4rem', }}
                              trMiscStyles={{ marginBottom: '4rem', }}
                            />
                            <Fragment>
                              <StaticTable
                                title={<Makam />}
                                data={watched}
                                linkContent
                                columns={[
                                  {
                                    title: 'שם נייר',
                                    name: 'name',
                                    styles: {
                                      fontWeight: '700',
                                      maxWidth: '17rem',
                                      paddingStart: '2rem',
                                    },
                                    render: value => value,
                                  },
                                  {
                                    title: 'שער אחרון',
                                    name: 'value',
                                    styles: {
                                      paddingStart: '2rem',
                                    },
                                    render: value => numToString(value),
                                  },
                                ]}
                              />
                              <MakamLink miscStyles={{ marginBottom: '4rem', }} />
                            </Fragment>
                            <StaticTable
                              title="עולות שנתי"
                              data={upYearly.assets}
                              linkContent
                              columns={[
                                {
                                  title: 'שם נייר',
                                  name: 'name',
                                  styles: {
                                    fontWeight: '700',
                                    maxWidth: '17rem',
                                    paddingStart: '2rem',
                                  },
                                  render: value => value,
                                },
                                {
                                  title: '% שינוי',
                                  percentage: true,
                                  name: 'yearlyYield',
                                  styles: function styles(value: number): StyleProps {
                                    return {
                                      ...(value
                                        ? {
                                          color:
                                              value < 0
                                                ? theme.color('negative')
                                                : theme.color('positive'),
                                        }
                                        : {}),
                                      direction: 'ltr',
                                      fontWeight: '700',
                                      paddingStart: '2rem',
                                      position: 'relative',
                                      textAlign: 'start',
                                    };
                                  },
                                  render: value => `
                                              ${value > 0 ? '' : value < 0 ? '-' : ''}
                                              ${value ? numToString(Math.abs(value)) : value}%
                                            `,
                                },
                              ]}
                              miscStyles={{ marginBottom: '4rem', }}
                              trMiscStyles={{ marginBottom: '4rem', }}
                            />
                            <StaticTable
                              title="יורדות שנתי"
                              data={downYearly.assets}
                              linkContent
                              columns={[
                                {
                                  title: 'שם נייר',
                                  name: 'name',
                                  styles: {
                                    fontWeight: '700',
                                    maxWidth: '17rem',
                                    paddingStart: '2rem',
                                  },
                                  render: value => value,
                                },
                                {
                                  title: '% שינוי',
                                  percentage: true,
                                  name: 'yearlyYield',
                                  styles: function styles(value: number): StyleProps {
                                    return {
                                      ...(value
                                        ? {
                                          color:
                                              value < 0
                                                ? theme.color('negative')
                                                : theme.color('positive'),
                                        }
                                        : {}),
                                      direction: 'ltr',
                                      fontWeight: '700',
                                      paddingStart: '2rem',
                                      position: 'relative',
                                      textAlign: 'start',
                                    };
                                  },
                                  render: value => `
                                              ${value > 0 ? '' : value < 0 ? '-' : ''}
                                              ${value ? numToString(Math.abs(value)) : value}%
                                            `,
                                },
                              ]}
                              miscStyles={{ marginBottom: '4rem', }}
                              trMiscStyles={{ marginBottom: '4rem', }}
                            />
                          </Fragment>
                        );
                      }}
                    </Query>
                  </RowItem>
                </PageRow>
              </Fragment>
            );
          }}
        </Query> */}
        <PageRow
          miscStyles={{
            display: [ { from: 'l', value: 'none', }, ],
          }}
        >
          <RowItem>
            <AdSlot {...createAdSlotProps(adUnits['finance.themarker.com.banner3'], adUnitPathTemplate)} />
          </RowItem>
        </PageRow>
        <PageRow>
          <Grid>
            <GridItem
              width={[ { until: 'l', value: 1, }, { from: 'l', value: 1 / 2, }, ]}
              miscStyles={{ marginBottom: [ { until: 'l', value: '4rem', }, ], }}
            >
              <RowItem title="קרנות סל">
                <SortableTable
                  queryPrefix="Etf"
                  filters={[ { section: 'type', subSection: 'etf', }, ]}
                  fragment="
                        name
                        value
                        changePercentage
                      "
                  link={{
                    href: '/etf',
                    as: '/etf',
                    text: 'לקרנות סל',
                  }}
                  fields={[
                    {
                      name: 'name',
                      display: 'שם נייר',
                      sortingOrder: 'asc',
                      style: () => ({
                        fontWeight: '700',
                        maxWidth: '17rem',
                        paddingStart: '2rem',
                      }),
                      headerStyle: {
                        width: '45%',
                      },
                      value: ({ name, }) => name,
                    },
                    {
                      name: 'value',
                      display: 'שער אחרון',
                      sortingOrder: 'desc',
                      value: ({ value, }) => numToString(value),
                    },
                    {
                      name: 'changePercentage',
                      display: '% שינוי',
                      sortingOrder: 'desc',
                      style: ({ changePercentage, }) => ({
                        ...(changePercentage
                          ? {
                            color:
                                changePercentage < 0
                                  ? theme.color('negative')
                                  : theme.color('positive'),
                          }
                          : {}),
                        direction: 'ltr',
                        fontWeight: '700',
                        paddingEnd: '2rem',
                        position: 'relative',
                        textAlign: 'start',
                      }),
                      value: ({ changePercentage, }) => `
                            ${changePercentage > 0 ? '' : changePercentage < 0 ? '-' : ''}
                            ${
                              changePercentage
                                ? numToString(Math.abs(changePercentage))
                                : changePercentage
                            }%
                          `,
                    },
                  ]}
                  initialSort="changePercentage"
                  count={5}
                />
              </RowItem>
            </GridItem>
            <GridItem width={[ { until: 'l', value: 1, }, { from: 'l', value: 1 / 2, }, ]}>
              <RowItem title="קרנות נאמנות">
                <SortableTable
                  queryPrefix="Mtf"
                  filters={[ { section: 'type', subSection: 'mtf', }, ]}
                  fragment="
                        name
                        value
                        changePercentage
                      "
                  link={{
                    href: '/mtf',
                    as: '/mtf',
                    text: 'לקרנות נאמנות',
                  }}
                  fields={[
                    {
                      name: 'name',
                      display: 'שם נייר',
                      sortingOrder: 'asc',
                      style: () => ({
                        fontWeight: '700',
                        maxWidth: '17rem',
                        paddingStart: '2rem',
                      }),
                      headerStyle: { width: '45%', },
                      value: ({ name, }) => name,
                    },
                    {
                      name: 'value',
                      display: 'שער אחרון',
                      sortingOrder: 'desc',
                      value: ({ value, }) => numToString(value),
                    },
                    {
                      name: 'changePercentage',
                      display: '% שינוי',
                      sortingOrder: 'desc',
                      style: ({ changePercentage, }) => ({
                        ...(changePercentage
                          ? {
                            color:
                                changePercentage < 0
                                  ? theme.color('negative')
                                  : theme.color('positive'),
                          }
                          : {}),
                        direction: 'ltr',
                        fontWeight: '700',
                        paddingEnd: '2rem',
                        position: 'relative',
                        textAlign: 'start',
                      }),
                      value: ({ changePercentage, }) => `
                            ${changePercentage > 0 ? '' : changePercentage < 0 ? '-' : ''}
                            ${
                              changePercentage
                                ? numToString(Math.abs(changePercentage))
                                : changePercentage
                            }%
                          `,
                    },
                  ]}
                  initialSort="changePercentage"
                  count={5}
                />
              </RowItem>
            </GridItem>
          </Grid>
        </PageRow>
      </Fragment>
    </MainLayout>
  );
}

export default index;
